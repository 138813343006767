<template>
  <div class="linear">
    <left-link-right>
      <!-- 左侧栏 -->
      <div class="left-tree" slot="leftLayout">
        <div class="search-input">
          <el-input v-model="keywords" placeholder="请输入关键字" />
          <i class="el-icon-search" @click="getTreeData"></i>
        </div>
        <div class="tree-wrapper">
          <el-tree class="my-tree" :data="leftTreeData" :default-expand-all="false" node-key="id" ref="leftTree" :default-checked-keys="defaultKeys" :default-expanded-keys="defaultExpandKeys" highlight-current @node-click="handleNodeClick" :props="defaultProps"> </el-tree>
        </div>
      </div>
      <!-- 右侧区域 -->
      <div class="right-info" slot="rightLayout">
        <div class="box center search-wrapper">
          <div class="box center search">
            <el-select v-model="workValue" placeholder="请选择工况" @change="handleWorkChange">
              <el-option v-for="item in workOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="btn-wrapper">
            <el-button class="reset-btn" @click="handleResetClick">重置</el-button>
            <el-button @click="handleAddClick" :class="!myUpload ? 'disable_icon' : ''">新增</el-button>
          </div>
        </div>
        <!-- 表格 -->
        <div class="table-list-page">
          <el-table :row-class-name="tableRowClassName" :header-cell-style="{ background: '#04153F', color: '#fff' }" :data="tableData">
            <el-table-column prop="branchName" label="分部工程"></el-table-column>
            <el-table-column prop="linkName" label="施工节段"></el-table-column>
            <el-table-column prop="workingName" label="工况"></el-table-column>
            <!-- <el-table-column prop="profile" label="截面"></el-table-column> -->
            <el-table-column width="260px" prop="measureTime" label="测量时间"></el-table-column>
            <el-table-column width="260px" prop="createTime" label="录入时间"></el-table-column>
            <el-table-column prop="id" label="操作" width="300px">
              <template slot-scope="scope">
                <span class="operate-txt" @click="handleViewRecord(scope.row.id)" :class="!myOption ? 'disable_icon' : ''">查看记录</span>
                <span class="operate-txt middle-txt" @click="handleViewReport(scope.row)" :class="!myOption ? 'disable_icon' : ''">查看报表</span>
                <span class="operate-txt" @click="handleDeleteRecord(scope.row.id)" :class="!myDel ? 'disable_icon' : ''">删除记录</span>
              </template>
            </el-table-column>
          </el-table>
          <common-pagination class="pageMain" :total="total" :pageSize="pageSize" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
        </div>
      </div>
    </left-link-right>
    <!-- 新增弹窗 -->
    <el-dialog title="新增" :visible.sync="addRecordDialogVisible" class="my-dialog add-dialog" top="7vh" :before-close="handleAddRecordDialogClose">
      <div class="report-wrapper">
        <div class="title">{{ baseForm.projectName }}施工监控记录水准高程测量记录表</div>
        <table class="report-table">
          <tr>
            <th colspan="2" style="width: 121px">项目名称</th>
            <td colspan="6">{{ baseForm.name }}</td>
          </tr>
          <tr>
            <th colspan="2">桥梁名称</th>
            <td colspan="2">{{ baseForm.projectName }}</td>
            <th>测量依据</th>
            <td colspan="3" style="width: 127px">{{ baseForm.standard }}</td>
          </tr>
          <tr>
            <th colspan="2"><span class="required-text">*</span>分部工程</th>
            <td colspan="2">
              <el-select v-model="addForm.branchId" placeholder="请选择分部工程" @change="handleBranchChange">
                <el-option v-for="item in projectOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </td>
            <th><span class="required-text">*</span>墩号</th>
            <td colspan="3">
              <el-select v-model="addForm.pierId" placeholder="请选择墩号">
                <el-option v-for="item in pierOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </td>
          </tr>
          <tr>
            <th colspan="2"><span class="required-text">*</span>施工节段</th>
            <td colspan="2">
              <el-select v-model="addForm.linkId" placeholder="请选择施工节段" @change="handleLinkChangeClick">
                <el-option v-for="item in sectionOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </td>
            <th><span class="required-text">*</span>工况</th>
            <td colspan="3">
              <el-select v-model="addForm.workingId" placeholder="请选择工况">
                <el-option v-for="item in addWorkOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </td>
          </tr>
          <tr>
            <th colspan="2"><span class="required-text">*</span>日期</th>
            <td width="110px">
              <el-date-picker v-model="addForm.measureTime1" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :clearable="false" placeholder="请选择"> </el-date-picker>
              <!-- <el-date-picker placeholder="请选择" type="datetime" :clearable="false" 
                                format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="addForm.measureTime1"> 
                            </el-date-picker> -->
            </td>
            <th style="width: 60px"><span class="required-text">*</span>时间</th>
            <td style="width: 115px">
              <el-time-picker is-range v-model="addForm.measureTime2" range-separator="至" start-placeholder="开始" end-placeholder="结束" format="HH:mm" value-format="HH:mm" placeholder="选择时间范围" :clearable="false"> </el-time-picker>
            </td>
            <th style="width: 55px"><span class="required-text">*</span>天气与温度</th>
            <td colspan="2">
              <el-input v-model="addForm.weather" placeholder="请输入"></el-input>
            </td>
          </tr>
          <tr>
            <th rowspan="2">位置</th>
            <th rowspan="2">截面</th>
            <th rowspan="2">钢筋头高(m)</th>
            <th colspan="3">水准尺读数(m)</th>
            <th style="width: 60px" rowspan="2">视线高(m)</th>
            <th rowspan="2">高程(m)</th>
          </tr>
          <tr>
            <th>后视</th>
            <th>间视</th>
            <th>前视</th>
          </tr>
          <tr v-show="recordData.length > 0">
            <td colspan="8" style="padding: 0">
              <div class="common-collapse" style="max-height: 365px; overflow: auto">
                <table class="my-scroll-table" cellspacing="0" cellpadding="0" width="100%">
                  <tr v-for="(item, index) in recordData" :key="index" class="center-tr">
                    <td class="first-td" width="40px">{{ item.site }}</td>
                    <td width="40px">{{ item.profile }}</td>
                    <td width="110px">{{ item.measureHight }}</td>
                    <td width="60px">{{ item.backView }}</td>
                    <td width="122px">{{ item.midView }}</td>
                    <td width="55px">{{ item.frontView }}</td>
                    <td width="60px">{{ item.sightHight }}</td>
                    <td class="end-td">{{ item.height }}</td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
        </table>
        <div class="btn-footer">
          <el-upload class="upload-demo" :headers="{ token }" :on-success="onsuccess" ref="upload" :data="updata" slot="tip" :disabled="isDisabeldUpload" :action="importUrl" :show-file-list="false">
            <el-button class="import-btn" @click="handleImportBtn">导入Excel测量记录</el-button>
          </el-upload>
          <el-button class="save-btn" @click="handleSaveBtn">保存</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 查看记录弹窗 -->
    <el-dialog title="查看记录" :visible.sync="seeRecordDialogVisible" class="my-dialog print-dialog" top="7vh" :before-close="handleSeeRecordDialogClose">
      <div class="report-wrapper">
        <div class="title">{{ baseForm.projectName }}施工监控记录水准高程测量记录表</div>
        <table class="report-table">
          <tr>
            <th colspan="2" style="width: 81px">项目名称</th>
            <td colspan="6">{{ baseForm.name }}</td>
          </tr>
          <tr>
            <th colspan="2">桥梁名称</th>
            <td colspan="3">{{ baseForm.projectName }}</td>
            <th style="width: 85px">测量依据</th>
            <td colspan="2">{{ baseForm.standard }}</td>
          </tr>
          <tr>
            <th colspan="2">分部工程</th>
            <td colspan="3">
              <span>{{ addForm.branchName }}</span>
            </td>
            <th>墩号</th>
            <td colspan="2">
              <span>{{ addForm.pierName }}</span>
            </td>
          </tr>
          <tr>
            <th colspan="2">施工节段</th>
            <td colspan="3">
              <span>{{ addForm.linkName }}</span>
            </td>
            <th>工况</th>
            <td colspan="2">
              <span>{{ addForm.workingName }}</span>
            </td>
          </tr>
          <tr>
            <th colspan="2">日期</th>
            <td style="width: 100px">
              <span>{{ addForm.measureTime1 }}</span>
            </td>
            <th style="width: 65px">时间</th>
            <td style="width: 100px">
              <span>{{ addForm.measureTime2 }}</span>
            </td>
            <th>天气与温度</th>
            <td colspan="2">
              <span>{{ addForm.weather }}</span>
            </td>
          </tr>
          <tr>
            <th rowspan="2">位置</th>
            <th rowspan="2">截面</th>
            <th rowspan="2">钢筋头高(m)</th>
            <th colspan="3">水准尺读数(m)</th>
            <th style="width: 75px" rowspan="2">视线高(m)</th>
            <th rowspan="2">高程(m)</th>
          </tr>
          <tr>
            <th>后视</th>
            <th>间视</th>
            <th>前视</th>
          </tr>
          <tr>
            <td colspan="8" style="padding: 0">
              <div class="collapse-div common-collapse">
                <table class="my-scroll-table" cellspacing="0" cellpadding="0" width="100%">
                  <tr v-for="(item, index) in recordData" :key="index" class="center-tr">
                    <td width="40px">{{ item.site }}</td>
                    <td width="40px">{{ item.profile }}</td>
                    <td width="100px">{{ item.measureHight }}</td>
                    <td width="65px">{{ item.backView }}</td>
                    <td width="100px">{{ item.midView }}</td>
                    <td width="85px">{{ item.frontView }}</td>
                    <td width="75px">{{ item.sightHight }}</td>
                    <td style="border-right: 0">{{ item.height }}</td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </el-dialog>
    <!-- 查看报表弹窗 -->
    <el-dialog title="查看报表" :visible.sync="reportDialogVisible" class="my-dialog print-dialog" top="7vh" :before-close="handleDialogClose">
      <div class="report-wrapper">
        <div class="title">{{ baseForm.projectName }}施工监控箱梁挂篮变形测量报表</div>
        <table class="report-table">
          <tr>
            <th style="width: 80px">测量日期</th>
            <td colspan="2" style="padding: 1px">{{ reportForm.measureTime }}</td>
            <th style="width: 80px">天气与温度</th>
            <td :colspan="reportForm.site.length - 1">{{ reportForm.weather }}</td>
          </tr>
          <tr>
            <th>测量工况</th>
            <td :colspan="reportForm.site.length + 2">{{ reportForm.branchName }}{{ reportForm.linkName }}{{ reportForm.workingName }}</td>
          </tr>
          <tr>
            <th rowspan="2">方向</th>
            <th rowspan="2" style="width: 80px">节段</th>
            <th :colspan="reportForm.site.length + 1">测点</th>
          </tr>
          <tr class="center-tr">
            <td width="80px" v-for="(item, index) in reportForm.site" :key="index">
              {{ item }}
            </td>
            <td>平均值</td>
          </tr>
          <tr>
            <td :colspan="reportForm.site.length + 3" style="padding: 0">
              <div style="max-height: 342px; overflow: auto">
                <table cellspacing="0" cellpadding="0" width="100%">
                  <tbody v-for="(item, index) in reportForm.list" :key="index">
                    <tr v-for="(eve, index) in item.list" class="center-tr">
                      <td :rowspan="item.list.length" v-if="index < 1" width="80px" style="border-left: 0; border-right: 0">{{ item.mileage }}</td>
                      <td width="80px">{{ eve.linkName }}</td>
                      <td width="80px" v-for="(childEve, index) in eve.value" :key="index">
                        {{ childEve }}
                      </td>
                      <td style="border-right: 0">{{ eve.mean }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
          <tr>
            <th style="height: 150px">截面示意图</th>
            <td :colspan="reportForm.site.length + 2">
              <img :src="imgSrc" alt="" style="width: 100%" />
            </td>
          </tr>
          <tr>
            <th>说明</th>
            <td :colspan="reportForm.site.length + 2" style="text-align: left; line-height: 2">
              1、报表按施工进度编号；<br />
              2、挂篮变形按照《公路桥涵施工技术规范》（JTG/T 3650-2020）17.5.1条允许最大变形为20mm执行。
            </td>
          </tr>
        </table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import LeftLinkRight from "@/components/LeftLinkRight";
import CommonPagination from "@/components/Pagination";
import * as echarts from "echarts";
import moment from "moment";
export default {
  name: "linear",
  data() {
    return {
      // token
      token: sessionStorage.getItem("token"),
      // 项目id
      projectId: sessionStorage.getItem("projectId"),
      // 结构树id
      treeId: "",
      // 搜索关键字
      keywords: "",
      projectValue: "",
      // 工程
      projectOptions: [],
      // 墩号
      pierOptions: [],
      // 节段
      sectionValue: "",
      sectionOptions: [],
      // 工况
      workValue: "",
      workOptions: [],
      // 根据节段获取工况
      addWorkOptions: [],
      // 线形监测 左侧树
      leftTreeData: [],
      // 默认选中的节点
      defaultKeys: [],
      // 默认展开的节点
      defaultExpandKeys: [],
      defaultProps: {
        children: "children",
        label: "name"
      },
      pageSize: 10,
      currentPage: 1,
      total: 0,
      // 操作类型 新增还是查看
      operateType: "",
      // 表格数据
      tableData: [],
      reportDialogVisible: false,
      // 报表
      reportData: [],
      reportForm: {
        site: [],
        list: []
      },
      // 新增弹窗
      addForm: {
        projectValue: "",
        pier: "",
        sectionValue: "",
        workValue: "",
        date: "",
        time: "",
        weather: "",
        measureTime1: "",
        measureTime2: []
      },
      // 新增弹窗
      addRecordDialogVisible: false,
      // 查看记录弹窗
      seeRecordDialogVisible: false,
      // 查看记录数据
      recordData: [],
      // 导入
      importUrl: "",
      // 基础信息
      baseForm: {},
      // 当前行
      currentRow: {},
      // 导入时额外参数
      updata: {},
      isDisabeldUpload: true,
      // 示意图
      imgSrc: ""
    };
  },
  created() {
    this.getTreeData();
    this.getBaseData();
    this.getAllWorkingData();
    this.getAllSectionBranchData();
    this.getAllSectionLinkData();
  },
  mounted() {
    console.log("接收到的当前项目id----------------------------", this.projectId);
    this.importUrl = `${this.baseURL}shape/monitor/export/${this.projectId}`;
  },
  methods: {
    // 获取当天时间
    getNowTime() {
      let date = new Date().getTime();
      let day = moment(date).format("YYYY-MM-DD");
      let nowTime = moment(date).format("HH:mm");
      let beforeTime = moment(date - 1 * 60 * 60 * 1000).format("HH:mm");
      this.$set(this.addForm, "measureTime1", day);
      this.$set(this.addForm, "measureTime2", [beforeTime, nowTime]);
    },
    // 获取记录表基础信息
    getBaseData() {
      this.$axios.get(`${this.baseURL}monitor/line/section/${this.projectId}`).then((res) => {
        console.log("获取基础数据", res);
        let detail = res.data.data;
        this.baseForm = detail;
      });
    },
    // 获取tree数据
    getTreeData() {
      this.$axios.get(`${this.baseURL}shape/monitor/tree/${this.projectId}?name=${this.keywords}`).then((res) => {
        console.log("获取树数据", res);
        let detail = res.data.data;
        this.leftTreeData = detail;
        this.treeId = detail[0].id;
        this.defaultExpandKeys = [detail[0].id];
        this.getTableData();
      });
    },
    // 获取表格数据
    getTableData() {
      let path = `shape/monitor/page?pageNum=${this.currentPage}&pageSize=${this.pageSize}&projectId=${this.projectId}&treeId=${this.treeId}&workingId=${this.workValue}`;
      this.$axios.get(`${this.baseURL}${path}`).then((res) => {
        console.log("获取表格数据", res);
        let detail = res.data.data;
        this.tableData = detail.records;
        this.total = detail.total;
      });
    },
    // 获取所有工况
    getAllWorkingData() {
      this.$axios.get(`${this.baseURL}section/working/${this.projectId}`).then((res) => {
        console.log("获取工况数据", res);
        let detail = res.data.data;
        this.workOptions = detail;
        if(this.workOptions.length > 0) {
          this.workValue = this.workOptions[0].id;
        }
      });
    },
    // 根据节段获取工况
    getWorkingDataByLink(linkId) {
      this.$axios.get(`${this.baseURL}section/link/working/${linkId}`).then((res) => {
        console.log("根据节段获取工况数据", res);
        let detail = res.data.data;
        this.addWorkOptions = detail;
      });
    },
    // 切换节段，获取对应工况
    handleLinkChangeClick(val) {
      this.$set(this.addForm, "workingId", "");
      this.getWorkingDataByLink(val);
    },
    // 获取所有分部工程
    getAllSectionBranchData() {
      this.$axios.get(`${this.baseURL}section/branch/${this.projectId}`).then((res) => {
        console.log("获取分部工程数据", res);
        let detail = res.data.data;
        this.projectOptions = detail;
      });
    },
    // 获取所有节段
    getAllSectionLinkData() {
      this.$axios.get(`${this.baseURL}section/link/${this.projectId}`).then((res) => {
        console.log("获取节段数据", res);
        let detail = res.data.data;
        this.sectionOptions = detail;
      });
    },
    // 获取所有墩号
    getAllPierData(branchId) {
      this.$axios.get(`${this.baseURL}section/pier/${this.projectId}?branchId=${branchId}`).then((res) => {
        console.log("获取墩号数据", res);
        let detail = res.data.data;
        this.pierOptions = detail;
      });
    },
    // 根据分部工程获取墩号数据
    handleBranchChange(val) {
      this.$set(this.addForm, "pierId", "");
      this.getAllPierData(val);
    },
    // 选择工况 刷新表格数据
    handleWorkChange(val) {
      console.log("选择工况下拉", val);
      this.getTableData();
    },
    // 重置
    handleResetClick() {
      this.workValue = "";
      this.getTableData();
    },
    // 点击树节点
    handleNodeClick(data) {
      console.log("点击树节点", data);
      this.treeId = data.id;
      this.getTableData();
    },
    handleImportBtn() {
      console.log("9999999999999999999999");
      if (!this.addForm.branchId) {
        this.$message.warning("导入文件前请选择分部工程");
        return false;
      }
      if (!this.addForm.pierId) {
        this.$message.warning("导入文件前请选择墩号");
        return false;
      }
      if (!this.addForm.linkId) {
        this.$message.warning("导入文件前请选择施工节段");
        return false;
      }
      this.isDisabeldUpload = false;
      this.updata = {
        branchId: this.addForm.branchId,
        linkId: this.addForm.linkId,
        pierId: this.addForm.pierId
      };
    },
    // 导入成功
    onsuccess(res, file, fileList) {
      console.log(res, file, fileList);
      if (res.errCode == 200) {
        this.$message.success("导入成功");
        this.recordData = res.data;
      } else {
        this.$message.error(res.errMsg);
      }
    },
    // 保存
    handleSaveBtn() {
      if (!this.addForm.branchId) {
        this.$message.warning("请选择分部工程");
        return;
      }
      if (!this.addForm.pierId) {
        this.$message.warning("请选择墩号");
        return;
      }
      if (!this.addForm.linkId) {
        this.$message.warning("请选择节段");
        return;
      }
      if (!this.addForm.workingId) {
        this.$message.warning("请选择工况");
        return;
      }
      if (!this.addForm.measureTime1) {
        this.$message.warning("请选择日期");
        return;
      }
      if (!this.addForm.measureTime2) {
        this.$message.warning("请选择时间");
        return;
      }
      if (!this.addForm.weather) {
        this.$message.warning("请输入天气与温度");
        return;
      }
      if (this.recordData.length == 0) {
        this.$message.warning("请导入Excel测量记录");
        return;
      }

      let time = this.addForm.measureTime2[0] + "~" + this.addForm.measureTime2[1];

      // let data = {
      //     branchId: this.addForm.branchId, // 分部工程id
      //     linkId: this.addForm.linkId, // 节段id
      //     measureTime: this.addForm.measureTime1 + ' ' + time, // 测量日期
      //     pierId: this.addForm.pierId, // 墩号id
      //     projectId: this.projectId, // 桥梁id
      //     weather: this.addForm.weather, // tianqi
      //     workingId: this.addForm.workingId, // 工况id
      //     list: this.recordData, // 表格数据
      // }
      let path = `shape/monitor/add?branchId=${this.addForm.branchId}&linkId=${this.addForm.linkId}&measureTime=${this.addForm.measureTime1 + " " + time}&pierId=${this.addForm.pierId}&projectId=${this.projectId}&weather=${this.addForm.weather}&workingId=${this.addForm.workingId}`;
      this.$axios.post(`${this.baseURL}${path}`, this.recordData).then((res) => {
        if (res.status == "200") {
          this.$message.success("保存成功");
          this.getTableData();
          this.getTreeData();
          this.addRecordDialogVisible = false;
        }
      });
    },
    // 新增
    handleAddClick() {
      if (!this.myUpload) {
        this.$message.error("您没有权限！");
        return;
      }
      this.operateType = "add";
      this.addRecordDialogVisible = true;
      this.recordData = [];
      this.addForm = {};
      this.pierOptions = [];
      this.addWorkOptions = [];
      this.getNowTime();
    },
    // 查看记录
    handleViewRecord(id) {
      if (!this.myOption) {
        this.$message.error("您没有查看权限！");
        return;
      }
      this.seeRecordDialogVisible = true;
      this.operateType = "see";
      this.$axios.get(`${this.baseURL}shape/monitor/info/${id}?treeId=${this.treeId}`).then((res) => {
        console.log("获取查看记录数据", res);
        let detail = res.data.data;
        this.recordData = detail.list;
        this.addForm = detail;
        let date = detail.measureTime.split(" ")[0];
        let time = detail.measureTime.split(" ")[1];
        this.$set(this.addForm, "measureTime1", date);
        this.$set(this.addForm, "measureTime2", time);
      });
    },
    // 获取截面示意图
    getExampleImage() {
      // 挂篮变形 type传3
      this.$axios.get(`${this.baseURL}sys/sysImages/hint/${this.projectId}?type=3`).then((res) => {
        console.log("获取截面示意图", res);
        if (res.data.data.id) {
          this.imgSrc = `${this.baseURL}sys/sysImages/img/${res.data.data.id}`;
        }
      });
    },
    // 查看报表
    handleViewReport(row) {
      if (!this.myOption) {
        this.$message.error("您没有查看权限！");
        return;
      }
      this.currentRow = row;
      this.reportDialogVisible = true;
      this.$axios.get(`${this.baseURL}shape/monitor/report/${row.id}?treeId=${this.treeId}`).then((res) => {
        console.log("获取查看报表数据", res);
        let detail = res.data.data;
        this.reportData = detail.list;
        this.reportForm = detail;
      });
      this.getExampleImage();
    },
    // 删除记录
    handleDeleteRecord(id) {
      if (!this.myDel) {
        this.$message.error("您没有删除权限！");
        return;
      }
      this.$confirm("确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm"
      }).then(() => {
        this.$axios.delete(`${this.baseURL}shape/monitor/${id}?treeId=${this.treeId}`).then((res) => {
          if (res.status == 200) {
            this.$message.success("删除成功");
            this.getTableData();
            this.getTreeData();
          } else {
            this.$message.error(res.errMsg);
          }
        });
      });
    },
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },
    // 分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    // 关闭查看报表弹窗
    handleDialogClose() {
      this.reportDialogVisible = false;
      this.recordData = [];
    },
    // 关闭新增弹窗
    handleAddRecordDialogClose() {
      this.addRecordDialogVisible = false;
      this.recordData = [];
    },
    // 关闭查看记录弹窗
    handleSeeRecordDialogClose() {
      this.seeRecordDialogVisible = false;
      this.recordData = [];
    }
  },
  components: {
    LeftLinkRight,
    CommonPagination
  }
};
</script>

<style scoped lang="scss">
.linear {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  .left-tree {
    width: 90%;
    height: 100%;
    padding: 30px 20px;
    border: 1px solid;
    border-image: linear-gradient(300deg, rgba(31, 87, 188, 1), rgba(28, 73, 165, 1)) 1 1;
    .tree-wrapper {
      height: calc(100% - 80px);
      .my-tree {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        background: none;
        margin-top: 10px;
        color: #fff;
      }
    }
  }
  .right-info {
    flex: 1;
    height: 100%;
    overflow-y: auto;
    padding: 30px;
    border: 1px solid;
    border-image: linear-gradient(300deg, rgba(31, 87, 188, 1), rgba(28, 73, 165, 1)) 1 1;
    .search-wrapper {
      line-height: 32px;
      justify-content: space-between;
      margin-bottom: 30px;
      .search .el-select {
        margin-right: 20px;
        ::v-deep .el-input {
          height: 32px;
        }
      }
      .reset-btn {
        margin-right: 15px;
      }
    }
  }
}
.tree-wrapper {
  height: 100%;
  display: flex;
  .dialog-left-tree {
    width: 45%;
    .my-left-tree {
      height: 100%;
      overflow-y: auto;
      background: none;
      margin-top: 10px;
      color: #fff;
    }
  }
  .dialog-right-tree {
    width: calc(55% - 240px);
    padding: 10px 20px;
  }
  .dialog-left-tree,
  .dialog-right-tree {
    background: linear-gradient(180deg, rgba(0, 35, 89, 0.67) 1%, rgba(19, 18, 68, 0.65));
    border: 1px solid #2667db;
  }
  .dialog-btn {
    width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .dialog-btn-group {
      text-align: center;
      line-height: 5;
      .el-button {
        width: 160px;
        padding: 0;
        margin: 0;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}
::v-deep .el-tree-node__content {
  height: 40px;
}
::v-deep .el-dialog .el-input {
  width: 100% !important;
  input {
    padding-left: 2px;
    padding-right: 0;
    border: 0;
    background: transparent !important;
  }
}
::v-deep div.el-dialog .el-select {
  width: 100%;
  .el-input {
    width: 100% !important;
    input.el-input__inner {
      border: 0;
      background: transparent !important;
    }
  }
}
::v-deep .my-dialog.add-dialog div.el-dialog {
  max-height: 848px;
}
::v-deep .print-dialog div.el-dialog {
  height: 848px !important;
}
::v-deep .my-dialog div.el-dialog {
  width: 595px !important;
  border: none;
  background: linear-gradient(0deg, #0a173d 2%, #0d3772);
  .el-dialog__header {
    border-bottom: none;
    .el-dialog__title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.86);
    }
  }
  .el-dialog__body {
    padding: 0 15px 20px 15px;
    color: #fff;
    .report-wrapper {
      border-top: 1px solid rgba(204, 218, 255, 0.3);
      .title {
        font-size: 16px;
        padding: 10px 0;
        text-align: center;
      }
      .report-table {
        width: 100%;
        font-size: 12px;
        border-collapse: collapse;
        tr:nth-child(odd) {
          background: #0c265a;
        }
        th,
        td {
          height: 35px;
          font-weight: 400;
          border: 1px solid rgba(204, 218, 255, 0.3);
          padding: 6px 3px;
          text-align: center;
          vertical-align: middle;
        }
        th {
          text-align: center;
        }
        td {
          text-align: left;
        }
        .center-tr td {
          text-align: center;
        }
        .required-text {
          color: red;
        }
        .common-collapse {
          tr:first-child td {
            border-top: 0;
          }
          tr:last-child td {
            border-bottom: 0;
          }
          .end-td {
            border-right: 0;
          }
        }
        .collapse-div {
          max-height: 485px;
          overflow: auto;
        }
        .report-collapse-div {
          max-height: 390px;
          overflow: auto;
        }
        .my-scroll-table .center-tr td {
          border-left: 0;
          border-bottom: 0;
        }
      }
      .btn-footer {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        text-align: center;
        .el-button {
          padding: 0;
          margin: 0;
          font-size: 12px;
          font-weight: 500;
        }
        .import-btn {
          width: 160px;
          margin-right: 20px;
        }
      }
    }
  }
}
.table-list-page {
  width: 100%;
  /* height: 100%; */
}
::v-deep .hui1 {
  background: #091d46 !important;
}
::v-deep .lv1 {
  background: #0c265a !important;
}
::v-deep .el-table td.el-table__cell,
::v-deep .el-table th.el-table__cell.is-leaf {
  font-size: 14px;
  text-align: center;
}
.middle-txt {
  margin: 0 10px;
}
.common-page.pageMain {
  margin-top: 15px;
}
::v-deep .el-range-editor.el-input__inner {
  width: 115px;
  background: none;
  border: 1px solid transparent;
  padding: 3px 0;
  .el-range-input {
    width: 45px;
    background: none;
  }
  .el-range-separator {
    width: 20px;
    color: #fff;
    padding: 0;
  }
  .el-date-editor .el-range__icon {
    margin-left: -5px;
  }
}
::v-deep .el-input__prefix {
  left: -8px;
  top: 0;
}
::v-deep .el-date-editor.el-input input {
  padding-left: 12px;
}
::v-deep .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background: rgba(50, 116, 255, 0.24) !important;
}
</style>
